import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useOrganisationsStore = defineStore('organisationsStore', {
  state: () => ({
    producerOrganisations: [] as any[],
    buyerOrganisations: [] as any[],
    processingEncashmentOrganisations: [] as any[],
    productionEncashmentOrganisations: [] as any[],
    activeproductionEncashmentOrganisations: [] as any[],
    loadingproductionEncashmentOrganisations: false,
    loadingactiveproductionEncashmentOrganisations: false
  }),
  actions: {
    async fetchBuyerOrganisations() {
      if (this.buyerOrganisations.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/legalEntities/buyerOrganisations')
          const orgs: Array<any> = []
          result.data.forEach((org: any) => {
            orgs.push({
              id: org.id,
              name: [org.name1, org.name2, org.uid].join(' ')
            })
          })
          this.buyerOrganisations = orgs
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchProducerOrganisations() {
      if (this.producerOrganisations.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/legalEntities/producerOrganisations')
          const orgs: Array<any> = []
          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1 })
          })
          this.producerOrganisations = orgs
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchProcessingEncashmentOrganisations() {
      if (this.processingEncashmentOrganisations.length === 0) {
        const defaultorgs = [
          { id: 54, name: 'Vereinigte Milchbauern Mitte-Ost' },
          { id: 183, name: 'Raclette Suisse' },
          { id: 184, name: 'CasAlp' }
        ]
        this.processingEncashmentOrganisations = [...defaultorgs]
        /*
                        try {
                          const result = await app.axios.get(apiURL + '/legalEntities/processngEncashmentOrganisations')
                          return await result.data
                        } catch (e) {
                          showError(e)
                        }
                        */
      }
    },
    async fetchProductionEncashmentOrganisations() {
      if (this.productionEncashmentOrganisations.length === 0) {
        try {
          this.loadingproductionEncashmentOrganisations = true
          const result = await app.axios.get(apiURL + '/legalEntities/productionEncashmentOrganisations', { params: { includeInactive: true } })
          const orgs: Array<any> = []

          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1, agisId: org.agisId })
          })

          this.productionEncashmentOrganisations = orgs
        } catch (e) {
          showError(e)
        } finally {
          this.loadingproductionEncashmentOrganisations = false
        }
      }
    },
    async fetchActiveproductionEncashmentOrganisations() {
      if (this.activeproductionEncashmentOrganisations.length === 0) {
        this.loadingactiveproductionEncashmentOrganisations = true
        try {
          const result = await app.axios.get(apiURL + '/legalEntities/productionEncashmentOrganisations', { params: { includeInactive: false } })
          const orgs: Array<any> = []

          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1 })
          })

          this.activeproductionEncashmentOrganisations = orgs
        } catch (e) {
          showError(e)
        } finally {
          this.loadingactiveproductionEncashmentOrganisations = false
        }
      }
    }
  }
})
